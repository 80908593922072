<script setup>
import { ref } from 'vue'
import LoginInput from './components/LoginInput'
import RegisterInput from './components/RegisterInput'
import ResetPassword from './components/ResetPassword'
//login图
const logo = require('@/assets/images/login/logo_txt.png')
const loginBg = require('@/assets/images/login/bg.png')
// 登陆注册所用
const showComponent = ref(0)
const componType = {
    0: LoginInput,
    1: RegisterInput,
    2: ResetPassword
}
const changeToLogin = () => {
    showComponent.value = 0
}
const toWebsite = () => {
    const linkUrl = 'https://www.xsms-club.com'
    window.open(linkUrl, '_blank')
}

//服务配置
const customImConfig = ref(null)
// const showCustomImConfigModal = () => {
//   customImConfig.value.centerDialogVisible = true
// }
</script>
<template>
    <el-container class='app_container'>
        <img class='login_bg' :src='loginBg' />
        <img class='login_logo' :src='logo' @click='toWebsite' alt='囍上媒捎客服系统' />
        <el-main class='login_box out-drawer animate__animated animate__slideInLeft'>
            <div class='login_left_side'>
                <div class='title_txt'>欢迎使用<br />囍上媒捎客服系统</div>
                <div class='indicator'/>
            </div>
            <div class='login_box_card'>
                <div class='title_box'>
                    <div class='left' />
                    <span class='txt'>用户登录</span>
                    <div class='right' />
                </div>
                <component :is='componType[0]' @changeToLogin='changeToLogin'></component>
            </div>
        </el-main>
        <el-footer>
            <div class='copyright'>
                <a href='javascript:;' @click='toWebsite'>版权所有© 囍上媒捎（思然）集团股份有限公司 苏ICP备19035109号</a>
            </div>
        </el-footer>
        <CustomImConfig ref='customImConfig' />
    </el-container>
</template>

<style lang='scss' scoped>
@media only screen and (min-width: 1500px) {
    .login_box {
        width: 1200px
    }
}

@media only screen and (max-width: 1500px) {
    .login_box {
        width: 65vw;
    }
}

.app_container {
    width: 100vw;
    height: 100vh;
    background-color: #F7F9FA;
    overflow-x: hidden;
    overflow-y: hidden;

    .login_bg {
        max-width: 100vw;
        max-height: 100vh;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        z-index: 0;
    }

    .login_logo {
        height: 56px;
        top: 36px;
        left: 36px;
        position: absolute;
        transition: all 0.3s;
        z-index: 1;

        &:hover {
            transform: scale(1.2);
        }
    }

    .login_box {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        display: flex;
        align-items: center;
        justify-content: center;
        //width: 1200px;
        z-index: 0;

        .login_left_side {
            background: url('@/assets/images/login/form_left_side_bg.png');
            height: 65vh;
            background-size: cover;

            .title_txt {
                font-size: 32px;
                font-family: Source Han Sans CN-Bold, Source Han Sans CN;
                font-weight: bold;
                color: #FFFFFF;
                line-height: 48px;
                margin-left: 40px;
                margin-right: 40px;
                margin-top: 58px;
            }

            .indicator {
                width: 24px;
                height: 4px;
                margin-left: 40px;
                margin-top: 24px;
                background: #FFFFFF;
                border-radius: 34px;
            }
        }

        .login_box_card {
            flex: 1;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            height: 65vh;
            background-color: #FFFFFF;
            border-radius: 0 8px 8px 0;

            .title_box {
                display: flex;
                align-items: center;
                margin-bottom: 40px;

                .left {
                    width: 40px;
                    height: 4px;
                    background: linear-gradient(360deg, #D9D9D9 0%, rgba(217, 217, 217, 0) 100%);
                    border-radius: 0;
                    opacity: 1;
                }

                .right {
                    width: 40px;
                    height: 4px;
                    background: linear-gradient(180deg, #D9D9D9 0%, rgba(217, 217, 217, 0) 100%);
                    border-radius: 0;
                    opacity: 1;
                }

                .txt {
                    font-size: 24px;
                    color: #32343E;
                    font-family: Source Han Sans CN-Medium, Source Han Sans CN;
                    font-weight: 500;
                    margin-left: 15px;
                    margin-right: 15px;
                }

            }

            .login_input_style {
                margin: 10px 0;
                width: 400px;
                height: 50px;
                font-size: 17px;
                padding: 0 10px;
            }

            .function_button_box {
                margin-top: 10px;
                width: 400px;

                button {
                    margin: 10px;
                    width: 380px;
                    height: 56px;
                    background: #EA4459;
                    border: none;
                    font-weight: 300;
                    font-size: 20px;
                    border-radius: 8px;

                    &:active {
                        background: linear-gradient(90deg, #0b83b2 0%, #363df4 100%);
                    }
                }
            }

            .function_button_extra {
                width: 400px;
                // height: 35px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 0 10px;
            }

            .custom_config,
            .reset_password {
                margin-left: 20px;
                font-family: 'PingFang SC';
                font-style: normal;
                font-weight: 400;
                font-size: 12px;
                line-height: 17px;
                text-align: right;
                color: #FFF;
                cursor: pointer;
            }

            .login_text {
                font-family: 'PingFang SC';
                font-style: normal;
                font-weight: 400;
                font-size: 12px;
                line-height: 17px;
                text-align: right;

                .login_text_isuserid {
                    display: inline-block;
                    // width: 100px;
                    color: #F9F9F9;
                }

                .login_text_tologin {
                    margin-right: 20px;
                    width: 80px;
                    color: #05B5F1;
                    cursor: pointer;

                    &:hover {
                        text-decoration: underline;
                    }
                }
            }
        }
    }

    .copyright {
        width: 100%;
        height: 30px;
        line-height: 30px;
        text-align: center;
        mix-blend-mode: normal;
        opacity: 0.5;
        font-family: 'PingFang SC';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 17px;
        color: #000000;
        position: absolute;
        bottom: 20px;

        a:link {
            color: #000000;
            text-decoration: none;
        }

        a:hover {
            color: #000000;
            text-decoration: underline;
        }

        a:active {
            color: #000000;
            text-decoration: none;
        }
    }
}
</style>
