import { validateNull } from './validate'
import * as CryptoJS from 'crypto-js'
import dateFormat from '@/utils/dateFormater'

// 表单序列化
export const serialize = data => {
    let list = []
    Object.keys(data).forEach(ele => {
        list.push(`${ele}=${data[ele]}`)
    })
    return list.join('&')
}
export const getObjType = obj => {
    var toString = Object.prototype.toString
    var map = {
        '[object Boolean]': 'boolean',
        '[object Number]': 'number',
        '[object String]': 'string',
        '[object Function]': 'function',
        '[object Array]': 'array',
        '[object Date]': 'date',
        '[object RegExp]': 'regExp',
        '[object Undefined]': 'undefined',
        '[object Null]': 'null',
        '[object Object]': 'object'
    }
    if (obj instanceof Element) {
        return 'element'
    }
    return map[toString.call(obj)]
}
/**
 * 对象深拷贝
 */
export const deepClone = data => {
    var type = getObjType(data)
    var obj
    if (type === 'array') {
        obj = []
    } else if (type === 'object') {
        obj = {}
    } else {
        // 不再具有下一层次
        return data
    }
    if (type === 'array') {
        for (var i = 0, len = data.length; i < len; i++) {
            obj.push(deepClone(data[i]))
        }
    } else if (type === 'object') {
        // eslint-disable-next-line guard-for-in
        for (var key in data) {
            obj[key] = deepClone(data[key])
        }
    }
    return obj
}
/**
 * 判断路由是否相等
 */
export const diff = (obj1, obj2) => {
    delete obj1.close
    var o1 = obj1 instanceof Object
    var o2 = obj2 instanceof Object
    if (!o1 || !o2) { /*  判断不是对象  */
        return obj1 === obj2
    }

    if (Object.keys(obj1).length !== Object.keys(obj2).length) {
        return false
        // Object.keys() 返回一个由对象的自身可枚举属性(key值)组成的数组,例如：数组返回下表：let arr = ["a", "b", "c"];console.log(Object.keys(arr))->0,1,2;
    }

    for (var attr in obj1) {
        var t1 = obj1[attr] instanceof Object
        var t2 = obj2[attr] instanceof Object
        if (t1 && t2) {
            return diff(obj1[attr], obj2[attr])
        } else if (obj1[attr] !== obj2[attr]) {
            return false
        }
    }
    return true
}
/**
 * 设置灰度模式
 */
export const toggleGrayMode = (status) => {
    if (status) {
        document.body.className = document.body.className + ' grayMode'
    } else {
        document.body.className = document.body.className.replace(' grayMode', '')
    }
}
/**
 * 设置主题
 */
export const setTheme = (name) => {
    document.body.className = name
}

/**
 *加密处理
 */
export const encryption = (params) => {
    let {
        data,
        type,
        param,
        key
    } = params
    const result = JSON.parse(JSON.stringify(data))
    if (type === 'Base64') {
        param.forEach(ele => {
            result[ele] = btoa(result[ele])
        })
    } else {
        param.forEach(ele => {
            var data = result[ele]
            key = CryptoJS.enc.Latin1.parse(key)
            var iv = key
            // 加密
            var encrypted = CryptoJS.AES.encrypt(
                data,
                key, {
                    iv: iv,
                    mode: CryptoJS.mode.CBC,
                    padding: CryptoJS.pad.ZeroPadding
                })
            result[ele] = encrypted.toString()
        })
    }
    return result
}

/**
 * 递归寻找子类的父类
 */

export const findParent = (menu, id) => {
    for (let i = 0; i < menu.length; i++) {
        if (menu[i].children.length !== 0) {
            for (let j = 0; j < menu[i].children.length; j++) {
                if (menu[i].children[j].id === id) {
                    return menu[i]
                } else {
                    if (menu[i].children[j].children.length !== 0) {
                        return findParent(menu[i].children[j].children, id)
                    }
                }
            }
        }
    }
}

/**
 * 动态插入css
 */

export const loadStyle = url => {
    const link = document.createElement('link')
    link.type = 'text/css'
    link.rel = 'stylesheet'
    link.href = url
    const head = document.getElementsByTagName('head')[0]
    head.appendChild(link)
}
/**
 * 判断路由是否相等
 */
export const isObjectValueEqual = (a, b) => {
    let result = true
    Object.keys(a).forEach(ele => {
        const type = typeof (a[ele])
        if (type === 'string' && a[ele] !== b[ele]) result = false
        else if (type === 'object' && JSON.stringify(a[ele]) !== JSON.stringify(b[ele])) result = false
    })
    return result
}
/**
 * 根据字典的value显示label
 */
export const findByValue = (dic, value) => {
    let result = ''
    if (validateNull(dic)) return value
    if (typeof (value) === 'string' || typeof (value) === 'number' || typeof (value) === 'boolean') {
        let index = 0
        index = findArray(dic, value)
        if (index !== -1) {
            result = dic[index].label
        } else {
            result = value
        }
    } else if (value instanceof Array) {
        result = []
        let index = 0
        value.forEach(ele => {
            index = findArray(dic, ele)
            if (index !== -1) {
                result.push(dic[index].label)
            } else {
                result.push(value)
            }
        })
        result = result.toString()
    }
    return result
}
/**
 * 根据字典的value查找对应的index
 */
export const findArray = (dic, value) => {
    for (let i = 0; i < dic.length; i++) {
        if (dic[i].value === value) {
            return i
        }
    }
    return -1
}
/**
 * 生成随机len位数字
 */
export const randomLenNum = (len, date) => {
    let random = ''
    random = Math.ceil(Math.random() * 100000000000000).toString().substr(0, len || 4)
    if (date) random = random + Date.now()
    return random
}
/**
 * 打开小窗口
 */
export const openWindow = (url, title, w, h) => {
    // Fixes dual-screen position                            Most browsers       Firefox
    const dualScreenLeft = window.screenLeft !== undefined ? window.screenLeft : screen.left
    const dualScreenTop = window.screenTop !== undefined ? window.screenTop : screen.top

    const width = window.innerWidth ? window.innerWidth : document.documentElement.clientWidth ? document.documentElement.clientWidth : screen.width
    const height = window.innerHeight ? window.innerHeight : document.documentElement.clientHeight ? document.documentElement.clientHeight : screen.height

    const left = ((width / 2) - (w / 2)) + dualScreenLeft
    const top = ((height / 2) - (h / 2)) + dualScreenTop
    const newWindow = window.open(url, title, 'toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=yes, copyhistory=no, width=' + w + ', height=' + h + ', top=' + top + ', left=' + left)

    // Puts focus on the newWindow
    if (window.focus) {
        newWindow.focus()
    }
}

export const filterForm = (form) => {
    let obj = {}
    Object.keys(form).forEach(ele => {
        if (!validateNull(form[ele])) {
            obj[ele] = form[ele]
        }
    })
    return obj
}

export const vaildData = (val, dafult) => {
    if (typeof val === 'boolean') {
        return val
    }
    return !validateNull(val) ? val : dafult
}

/**
 * list对象去重（根据对象的ID去重）
 * @param arr
 * @returns {*}
 */
export const unique = (arr) => {
    const res = new Map()
    return arr.filter((arr) => !res.has(arr.id) && res.set(arr.id, 1))
}


/**
 * 复制文本
 * @param text
 */
export function copyText(text) {
    this.$Clipboard({
        text: text
    }).then(() => {
        this.$message.success('复制成功')
    }).catch(() => {
        this.$message.error('复制失败')
    })
}


// 通用下载方法
export function download(fileName) {
    window.open('/upms/file/download?fileName=' + encodeURI(fileName) + '&delete=' + true, '_bank')
}

/**
 * 构造树型结构数据
 * @param {*} data 数据源
 * @param {*} id id字段 默认 'id'
 * @param {*} parentId 父节点字段 默认 'parentId'
 * @param {*} children 孩子节点字段 默认 'children'
 */
export function handleTree(data, id, parentId, children) {
    id = id || 'id'
    parentId = parentId || 'parentId'
    children = children || 'children'
    let result = []
    data = JSON.parse(JSON.stringify(data))
    if (!Array.isArray(data)) {
        return result
    }
    data.forEach(item => {
        delete item[children]
    })
    let map = {}
    data.forEach(item => {
        map[item[id]] = item
    })
    data.forEach(item => {
        let parent = map[item[parentId]]
        if (parent) {
            parent[children] || (parent[children] = [])
            parent[children].push(item)
        } else {
            result.push(item)
        }
    })
    return result
}

export const setVarStyle = (key, value, dom = document.documentElement) => {
    dom.style.setProperty(key, value)
}

export const isWorkTime = () => {
    const nowTime = parseInt(dateFormat('HHmm', new Date().getTime()))
    //9:00~12:00
    if (nowTime >= 900 && nowTime <= 1200) {
        return true
    }
    //13:30~18:00
    if (nowTime >= 1230 && nowTime <= 1800) {
        return true
    }
    return false
}