// 配置编译环境和线上环境之间的切换
const env = process.env
//前端密码密钥，必须16位，和nacos配置文件base-gateway-dev.yml中的security.encode.key对应
const securityKey = '1234567891234567'
//环信注册ID前缀（用户手机号注册的，环信ID必须字母数字组合）
const EM_USERID_PREFIX = 'xsms'

if (env.NODE_ENV == 'development') {

} else if (env.NODE_ENV == 'production') {

} else if (env.NODE_ENV == 'test') {

}


export {
    env,
    securityKey,
    EM_USERID_PREFIX
}
