import request from '@/utils/request'

export function getKefuConfig() {
    return request({
        url: '/upms/kefu/config/get',
        method: 'get'
    })
}

export function getFastScript(query) {
    return request({
        url: '/upms/kefu/fastscript/list',
        method: 'get',
        params: query
    })
}

export const saveFastScript = (data) => {
    return request({
        url: '/upms/kefu/fastscript',
        method: 'post',
        data: data
    })
}

export const deleteFastScript = (id) => {
    return request({
        url: '/upms/kefu/fastscript/' + id,
        method: 'delete'
    })
}

export function getStarUserList(query) {
    return request({
        url: '/upms/kefu/star/user/list',
        method: 'get',
        params: query
    })
}

export function saveStarUser(data) {
    return request({
        url: '/upms/kefu/star/user/save',
        method: 'post',
        data: data
    })
}

export function getKefuAppariseList(query) {
    return request({
        url: '/upms/kefu/appraise/list',
        method: 'get',
        params: query
    })
}

export function getKefuAppariseStat(kefuUsername) {
    return request({
        url: '/upms/kefu/appraise/stat/' + kefuUsername,
        method: 'get'
    })
}
export function getKefuUserList() {
    return request({
        url: '/upms/kefu/user/list',
        method: 'get'
    })
}

export function getKefuUserStatInfo(query) {
    return request({
        url: '/upms/kefu/user/stat/info',
        method: 'get',
        params: query
    })
}

export function getKefuUserStateStatList(query) {
    return request({
        url: '/upms/kefu/user/state/stat/list',
        method: 'get',
        params: query
    })
}

export function getServePersonNumStatList(query) {
    return request({
        url: '/upms/kefu/msg/reply/record/getServePersonNumStatList',
        method: 'get',
        params: query
    })
}

export function getKefuConversationAvgWaitTimeStatList(query) {
    return request({
        url: '/upms/kefu/conversation/getAvgWaitTimeStatList',
        method: 'get',
        params: query
    })
}
export function saveKefuConversationWaitTime(data) {
    return request({
        url: '/upms/kefu/conversation/saveWaitTimeRecord',
        method: 'post',
        data: data
    })
}

export function saveKefuMsgReplyRecord(data) {
    return request({
        url: '/upms/kefu/msg/reply/record/save',
        method: 'post',
        data: data
    })
}

export function get100PercentReplyRatioStatList(query) {
    return request({
        url: '/upms/kefu/msg/reply/record/get100PercentReplyRatioStatList',
        method: 'get',
        params: query
    })
}

