<script setup>
import { computed, reactive, ref, watch } from 'vue'
import { ElMessage } from 'element-plus'
import { EaseChatClient } from '@/IM/initwebsdk'
import { handleSDKErrorNotifi } from '@/utils/handleSomeData'
import { randomLenNum } from '@/utils/util'
import { useStore } from 'vuex'
import { usePlayRing } from '@/hooks'

const store = useStore()
const iconName = require('@/assets/images/login/icon_username.png')
const iconPwd = require('@/assets/images/login/icon_pwd.png')
const iconCode = require('@/assets/images/login/icon_code.png')

const code = reactive({
    src: '/code',
    value: '',
    len: 4,
    type: 'image'
})

const username = window.localStorage.getItem('login_kefu_username')
const password = window.localStorage.getItem('login_kefu_password')
const rememberMe = window.localStorage.getItem('login_kefu_remember_me')
const loginForm = reactive({
    username: username === undefined ? loginForm.username : username,
    password: password === undefined ? loginForm.password : password,
    code: '',
    redomStr: '',
    rememberMe: rememberMe === undefined ? false : Boolean(rememberMe)
})

const buttonLoading = ref(false)
//根据登陆初始化一部分状态
const loginState = computed(() => store.state.loginState)
watch(loginState, (newVal) => {
    if (newVal) {
        buttonLoading.value = false
        loginForm.username = ''
        loginForm.password = ''
    }
})
const rules = reactive({
    username: [
        { required: true, message: '请输入登录账号', trigger: 'blur' }
    ],
    password: [
        { required: true, message: '请输入登录密码', trigger: 'blur' },
        { min: 6, message: '密码长度最少为6位', trigger: 'blur' }
    ],
    code: [
        { required: true, message: '请输入验证码', trigger: 'blur' },
        { min: 4, max: 4, message: '验证码长度为4位', trigger: 'blur' }
    ]
})

//获取验证码
const refreshCode = async () => {
    loginForm.code = ''
    loginForm.randomStr = randomLenNum(code.len, true)
    code.type === 'text'
        ? (code.value = randomLenNum(code.len))
        : (code.src = `${window.location.origin}/code?randomStr=${loginForm.randomStr}`)
}

refreshCode()

const handleLogin = async () => {
    const { clickRing } = usePlayRing()
    clickRing()
    buttonLoading.value = true
    store.dispatch('LoginByUsername', loginForm).then((res) => {
        if (res === 'not kefu user') {
            refreshCode()
            buttonLoading.value = false
            ElMessage.error('该用户不是客服人员，无法使用该系统')
        } else {
            loginToEM()
        }
    }).catch(() => {
        refreshCode()
        buttonLoading.value = false
    })
}

const loginToEM = async () => {
    try {
        const { accessToken } = await EaseChatClient.open({
            user: loginForm.username.toString(),
            pwd: loginForm.password.toString()
        })
        window.localStorage.setItem('EASEIM_loginUser', JSON.stringify({
            user: loginForm.username,
            accessToken: accessToken
        }))
        if (loginForm.rememberMe) {
            window.localStorage.setItem('login_kefu_username', loginForm.username)
            window.localStorage.setItem('login_kefu_password', loginForm.password)
            window.localStorage.setItem('login_kefu_remember_me', loginForm.rememberMe)
        } else {
            window.localStorage.removeItem('login_kefu_username')
            window.localStorage.removeItem('login_kefu_password')
            window.localStorage.removeItem('login_kefu_remember_me')
        }
        await store.dispatch('UpdateOnlineState', {
            kefuUsername: loginForm.username,
            state: 1
        })
        await store.dispatch('GetUserInfo')
    } catch (error) {
        console.log('>>>>登陆失败', error)
        const { data: { extraInfo } } = error
        handleSDKErrorNotifi(error.type, extraInfo.errDesc)
        loginForm.username = ''
        loginForm.password = ''
        loginForm.code = ''
        // store.dispatch('LogOut')
        await refreshCode()
    } finally {
        buttonLoading.value = false
    }
}

</script>

<template>
    <el-form :model='loginForm' :rules='rules'>
        <el-form-item prop='username'>
            <el-input
                class='login_input_style'
                v-model='loginForm.username'
                placeholder='登录账号'
                clearable
            >
                <template #prepend>
                    <div class='prepend_box'>
                        <img :src='iconName' class='icon' />
                        <div class='line' />
                    </div>
                </template>
            </el-input>
        </el-form-item>
        <el-form-item prop='password'>
            <el-input
                class='login_input_style'
                type='password'
                v-model='loginForm.password'
                placeholder='登录密码'
            >
                <template #prepend>
                    <div class='prepend_box'>
                        <img :src='iconPwd' class='icon' />
                        <div class='line' />
                    </div>
                </template>
            </el-input>
        </el-form-item>
        <el-form-item prop='code'>
            <el-input
                class='login_input_style login_input_style__valid_code'
                v-model='loginForm.code'
                placeholder='验证码'
                @keyup.enter.native='handleLogin'
            >
                <template #prepend>
                    <div class='prepend_box'>
                        <img :src='iconCode' class='icon' />
                        <div class='line' />
                    </div>
                </template>
            </el-input>
            <div class='login-code'>
                <span class='login-code-img'
                      @click='refreshCode'
                      v-if="code.type === 'text'">{{ code.value }}</span>
                <img :src='code.src'
                     class='login-code-img'
                     @click='refreshCode'
                     v-else />
            </div>
        </el-form-item>
        <el-form-item>
            <el-checkbox v-model='loginForm.rememberMe'>记住密码</el-checkbox>
        </el-form-item>
        <el-form-item>
            <div class='function_button_box'>
                <el-button
                    v-if='loginForm.username && loginForm.password && loginForm.code'
                    class='haveValueBtn'
                    :loading='buttonLoading'
                    @click='handleLogin'
                >登录
                </el-button
                >
                <el-button v-else class='notValueBtn'>登录</el-button>
            </div>
        </el-form-item>
    </el-form>
</template>

<style lang='scss' scoped>

@media only screen and (min-width: 1500px) {
    .login_input_style {
        width: 380px;

        &__valid_code {
            width: 270px;
        }
    }

    .function_button_box {
        width: 380px;
        button {
            width: 380px;
        }
    }
}

@media only screen and (max-width: 1500px) {
    .login_input_style {
        width: 280px;

        &__valid_code {
            width: 170px;
        }
    }

    .function_button_box {
        width: 280px;
        button {
            width: 280px;
        }
    }
}

.login_input_style {
    //width: 380px;
    margin-top: 10px;

    .prepend_box {
        display: flex;
        align-items: center;

        .icon {
            width: 20px;
            height: 20px;
        }

        .line {
            width: 1px;
            height: 20px;
            background-color: #E4E5E7;
            margin-left: 20px;
        }
    }
}

::v-deep .el-input__wrapper {
    border-radius: 8px;
    height: 52px;
    border-right-style: solid;
    border-right-width: 1px;
    border-right-color: #E4E5E7;
    border-top-style: solid;
    border-top-width: 1px;
    border-top-color: #E4E5E7;
    border-bottom-style: solid;
    border-bottom-width: 1px;
    border-bottom-color: #E4E5E7;
    box-shadow: none;
    padding: 0;
}

::v-deep .el-input__inner {
    padding: 0 20px 0 20px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #3a3a3a;

    &::placeholder {
        font-family: 'PingFang SC';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        color: #A4A6B0;
    }
}

::v-deep .el-input__suffix-inner {
    font-size: 20px;
    margin-right: 15px;
}

::v-deep .el-form-item__error {
    margin-left: 16px;
}

::v-deep .el-input-group__prepend {
    background-color: transparent;
    padding: 0 0 0 20px;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    border-left-style: solid;
    border-left-width: 1px;
    border-left-color: #E4E5E7;
    border-top-style: solid;
    border-top-width: 1px;
    border-top-color: #E4E5E7;
    border-bottom-style: solid;
    border-bottom-width: 1px;
    border-bottom-color: #E4E5E7;
    box-shadow: none;
}

::v-deep .el-input-group__append {
    width: 60px;
    color: #fff;
    font-weight: 400;
    background-color: transparent;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    border: 1px solid #E4E5E7;
}

.login_text {
    font-family: 'PingFang SC';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 17px;
    text-align: right;

    .login_text_isuserid {
        display: inline-block;
        // width: 100px;
        color: #f9f9f9;
    }

    .login_text_tologin {
        margin-right: 20px;
        width: 80px;
        color: #05b5f1;
        cursor: pointer;

        &:hover {
            text-decoration: underline;
        }
    }
}

.login_remember_pwd_box {
    display: flex;
    align-items: center;

    .txt {
        font-size: 14px;
        font-family: Source Han Sans CN-Regular, Source Han Sans CN;
        font-weight: 400;
        color: #A4A6B0;
    }
}

::v-deep .el-checkbox {
    margin-right: 5px;
    color: #A4A6B0;
}

::v-deep .el-checkbox__input.is-checked .el-checkbox__inner {
    background-color: #EA4459;
    border-color: #EA4459;
}

::v-deep .el-checkbox__input.is-checked + .el-checkbox__label {
    color: #A4A6B0;
}

.function_button_box {
    margin-top: 10px;
    //width: 380px;

    button {
        margin-top: 10px;
        //width: 380px;
        height: 56px;
        font-size: 20px;
        font-weight: 500;
        border-radius: 8px;
    }

    .haveValueBtn {
        background: #EA4459;
        border: none;
        font-size: 20px;
        font-weight: 500;
        color: #ffffff;

        &:active {
            background: #EA4459;
        }
    }

    .notValueBtn {
        border: none;
        font-size: 20px;
        font-weight: 500;
        background: #000000;
        mix-blend-mode: normal;
        opacity: 0.3;
        color: #ffffff;
        cursor: not-allowed;
    }
}

.login-code {
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin-left: 10px;
}

.login-code-img {
    width: 100px;
    height: 50px;
    line-height: 52px;
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 5px;
    text-indent: 5px;
    text-align: center;
    margin-top: 8px;
    border-radius: 8px;
    border: 1px solid #FFF;
}

</style>
