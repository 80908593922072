import request from '@/utils/request'

export function getMemberListByEMIds(query) {
    return request({
        url: '/xsms/member/kefu/getByEMIds',
        method: 'get',
        params: query
    })
}

export function searchMemberList(query) {
    return request({
        url: '/xsms/member/kefu/search',
        method: 'get',
        params: query
    })
}

export function saveMemberKefuRemark(data) {
    return request({
        url: '/xsms/member/kefu/remark/save',
        method: 'post',
        data: data
    })
}
