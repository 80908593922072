const onLineStatus = {
    Online: { label: '在线', value: 1, style: 'background-color:#49FD1D' },
    // Leave: { label: '离开', style: 'background-color:#4E4239' },
    // Cloaking: {
    //     label: '勿扰',
    //     style: 'background-color:#F27014'
    // },
    Offline: { label: '离线', value: 0, style: 'background-color:#BEC1BD' }
}

export default onLineStatus
