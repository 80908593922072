const getters = {
    userInfo: state => state.kefuUser.userInfo,
    access_token: state => state.kefuUser.access_token,
    refresh_token: state => state.kefuUser.refresh_token,
    expires_in: state => state.kefuUser.expires_in,
    roles: state => state.kefuUser.roles,
    permissions: state => state.kefuUser.permissions,

    loginUserInfo: state => state.loginUserInfo,
    loginUserOnlineStatus: state => state.loginUserOnlineStatus
}
export default getters
