import { getStore, setStore, removeStore } from '@/utils/store'
import { getUserInfo, loginByPhone, loginByUsername, logout, refreshToken, updateUserOnlineState } from '@/api/login'
import { getStarUserList, getFastScript } from '@/api/kefu'
import { encryption } from '@/utils/util'
import { securityKey } from '@/config/env'

const kefuUser = {
    state: {
        userInfo: {},
        expires_in: getStore({
            name: 'expires_in'
        }) || '',
        access_token: getStore({
            name: 'access_token'
        }) || '',
        refresh_token: getStore({
            name: 'refresh_token'
        }) || '',
        star_users: getStore({
            name: 'star_users'
        }) || [],
        fast_scripts: getStore({
            name: 'fast_scripts'
        }) || []
    },
    actions: {
        // 根据用户名登录
        LoginByUsername({ commit }, userInfo) {
            const user = encryption({
                data: userInfo,
                key: securityKey,
                param: ['password']
            })
            return new Promise((resolve, reject) => {
                loginByUsername(user.username, user.password, user.code, user.randomStr).then(response => {
                    const data = response.data
                    if (data.user_type !== '10') {
                        resolve('not kefu user')
                    }
                    commit('SET_ACCESS_TOKEN', data.access_token)
                    commit('SET_REFRESH_TOKEN', data.refresh_token)
                    commit('SET_EXPIRES_IN', data.expires_in)
                    commit('CLEAR_LOCK')
                    resolve()
                }).catch(error => {
                    reject(error)
                })
            })
        },
        // 根据手机号登录
        LoginByPhone({ commit }, userInfo) {
            return new Promise((resolve, reject) => {
                loginByPhone(userInfo.phone, userInfo.code).then(response => {
                    const data = response.data
                    commit('SET_ACCESS_TOKEN', data.access_token)
                    commit('SET_REFRESH_TOKEN', data.refresh_token)
                    commit('SET_EXPIRES_IN', data.expires_in)
                    commit('CLEAR_LOCK')
                    resolve()
                }).catch(error => {
                    reject(error)
                })
            })
        },
        GetUserInfo({ commit }) {
            return new Promise((resolve, reject) => {
                getUserInfo().then((res) => {
                    const data = res.data.data || {}
                    commit('SET_USER_INFO', data.sysUser)
                    resolve(data)
                }).catch(() => {
                    reject()
                })
            })
        },
        // 刷新token
        RefreshToken({ commit, state }) {
            return new Promise((resolve, reject) => {
                refreshToken(state.refresh_token).then(response => {
                    const data = response.data
                    commit('SET_ACCESS_TOKEN', data.access_token)
                    commit('SET_REFRESH_TOKEN', data.refresh_token)
                    commit('SET_EXPIRES_IN', data.expires_in)
                    commit('CLEAR_LOCK')
                    resolve()
                }).catch(error => {
                    reject(error)
                })
            })
        },
        // 登出
        LogOut({ commit }) {
            return new Promise((resolve, reject) => {
                logout().then(() => {
                    commit('SET_USER_INFO', {})
                    commit('SET_ACCESS_TOKEN', '')
                    commit('SET_REFRESH_TOKEN', '')
                    commit('SET_EXPIRES_IN', '')
                    commit('SET_STAR_USERS', [])
                    commit('SET_FAST_SCRIPTS', [])
                    removeStore({name: 'contact_keyword'})
                    removeStore({name: 'contact_list'})
                    window.localStorage.removeItem('EASEIM_loginUser')
                    resolve()
                }).catch(error => {
                    reject(error)
                })
            })
        },
        // 注销session
        FedLogOut({ commit }) {
            return new Promise(resolve => {
                commit('SET_USER_INFO', {})
                commit('SET_ACCESS_TOKEN', '')
                commit('SET_REFRESH_TOKEN', '')
                commit('SET_EXPIRES_IN', '')
                commit('SET_STAR_USERS', [])
                commit('SET_FAST_SCRIPTS', [])
                removeStore({name: 'contact_keyword'})
                removeStore({name: 'contact_list'})
                window.localStorage.removeItem('EASEIM_loginUser')
                resolve()
            })
        },
        //更新客服在线状态
        UpdateOnlineState( { commit, state}, params) {
            return new Promise((resolve, reject) => {
                updateUserOnlineState(params).then(() => {
                    if (state.userInfo && state.userInfo.id) {
                        state.userInfo.onlineState = params.state
                        commit('SET_USER_INFO', state.userInfo)
                    }
                    resolve()
                }).catch(error => {
                    reject(error)
                })
            })
        },
        //获取星标用户
        GetStarUsers({ commit, state }) {
            return new Promise((resolve, reject) => {
                getStarUserList({
                    kefuUsername: state.userInfo.username
                }).then((res) => {
                    const datas = res.data.data || []
                    commit('SET_STAR_USERS', datas)
                    resolve(datas)
                }).catch(() => {
                    reject()
                })
            })
        },
        //获取快捷短语
        GetFastScripts({ commit, state }) {
            return new Promise((resolve, reject) => {
                getFastScript({
                    kefuUsername: state.userInfo.username
                }).then((res) => {
                    const datas = res.data.data || []
                    commit('SET_FAST_SCRIPTS', datas)
                    resolve(datas)
                }).catch(() => {
                    reject()
                })
            })
        },
    },
    mutations: {
        SET_ACCESS_TOKEN: (state, access_token) => {
            state.access_token = access_token
            setStore({
                name: 'access_token',
                content: state.access_token,
                type: 'session'
            })
        },
        SET_EXPIRES_IN: (state, expires_in) => {
            state.expires_in = expires_in
            setStore({
                name: 'expires_in',
                content: state.expires_in,
                type: 'session'
            })
        },
        SET_REFRESH_TOKEN: (state, rfToken) => {
            state.refresh_token = rfToken
            setStore({
                name: 'refresh_token',
                content: state.refresh_token,
                type: 'session'
            })
        },
        SET_USER_INFO: (state, userInfo) => {
            state.userInfo = userInfo
        },
        SET_STAR_USERS: (state, starUsers) => {
            state.star_users = starUsers
            setStore({
                name: 'star_users',
                content: state.star_users,
                type: 'session'
            })
        },
        SET_FAST_SCRIPTS: (state, datas) => {
            state.fast_scripts = datas
            setStore({
                name: 'fast_scripts',
                content: state.fast_scripts,
                type: 'session'
            })
        },
    }
}
export default kefuUser
