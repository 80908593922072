import axios from 'axios'
import store from '@/store'
import router from '@/router'
import errorCode from '@/constant/xsmsErrorCode'
import { ElMessage } from 'element-plus'
import { EaseChatClient } from '@/IM/initwebsdk'

// create an axios instance
const service = axios.create({
    withCredentials: true,
    // baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
    // withCredentials: true, // send cookies when cross-domain requests
    timeout: 30000, // request timeout
    headers: { 'Content-Type': 'application/json' }
})
// request interceptor
service.interceptors.request.use(
    (config) => {
        // do something before request is sent
        const isToken = (config.headers || {}).isToken === false
        const token = !isToken ? store.getters.access_token : ''
        if (token && !isToken) {
            config.headers['Authorization'] = 'Bearer ' + token// token
        }
        return config
    },
    (error) => {
        // do something with request error
        console.log('request error', error) // for debug
        return Promise.reject(error)
    }
)

// response interceptor
service.interceptors.response.use(
    /**
     * If you want to get http information such as headers or status
     * Please return  response => response
     */

    /**
     * Determine the request status by custom code
     * Here is just an example
     * You can also judge the status by HTTP Status Code
     */
    (res) => {
        const message = handleResponse(res)
        if (message) {
            ElMessage({
                center: true,
                message: message,
                type: 'error'
            })
        } else {
            return res
        }
    },
    (error) => {
        const message = handleResponse(error.response)
        if (message) {
            ElMessage({
                center: true,
                message: message,
                type: 'error'
            })
            return Promise.reject(new Error(message))
        }
        return Promise.reject(error)
    }
)

const handleResponse = (res) => {
    if (!res) {
        return errorCode['default']
    }
    const status = Number(res.status) || 200
    const message = status === 200 ? '操作成功' : res.data.msg || errorCode[status] || errorCode['default']
    if (status === 401 && res.data.data === 'invalid_token') {
        EaseChatClient.close()
        store.dispatch('FedLogOut').then(() => {
            router.push({path: '/login'})
        })
        return '登录过期，请重新登录'
    }
    if (status !== 200 || res.data.code === 1) {
        return message
    }
    return null
}

export default service
